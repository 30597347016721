import React, {useEffect, useRef, useState} from "react";
import {useGlobalAudioPlayer} from "react-use-audio-player";
import artJson from "./art.json";

export const Art = (props: { runArt: boolean, stopRun: () => void }) => {

    const [artState, setArtState] = useState({
        artVal: 0,
        interval: undefined as undefined|NodeJS.Timer
    });

    const player = useGlobalAudioPlayer();
    useEffect(() => {
        player.loop(true);
        player.load('/audio.mp3');
    }, []);

    useEffect(() => {
        if(props.runArt) {
            if(!player.playing)
                player.play();
            setArtState({
                ...artState,
                interval: setInterval(() => {
                    let val = artState.artVal + 1;
                    if(val >= artJson.values.length)
                        val = 0;
                    setArtState({ ...artState, artVal: val });
                }, 1500)
            });
        }
        else {
            player.stop();
            clearInterval(artState.interval);
            setArtState({
                ...artState,
                interval: undefined
            });
        }
    }, [artState.artVal, props.runArt]);

    const r = Math.random()*500;
    const g = Math.random()*(500-r);
    const b = Math.random()*(500-r-g);

    const color = `rgb(${r},${g},${b})`;

    return (
        <>
            <br/>
            {
                props.runArt &&
                <span
                    style={{
                        display: "inline-block",
                        whiteSpace: "pre-line",
                        fontSize: "5px",
                        lineHeight: "5px",
                        userSelect: "none",
                        color: color,
                    }}
                    onClick={() => props.stopRun?.()}
                >{artJson.values[artState.artVal]}</span>
            }
        </>
    )
};