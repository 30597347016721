import React, {useEffect, useState} from 'react';
import {TimeseriesNativeCoordinates} from "./service/climateEngine";
import constants from "./constants.module";
import {Art} from "./_partial/Art";

const Label = (props: { text: string }) => {
    return (
        <div style={{ fontSize: 15, width: 80 }}>
            {props.text}
        </div>
    );
};

const InputField = (props: {
    label: string,
    value?: string,
    onChange?: (value: string) => void
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', margin: 10 }}>
            <Label text={props.label} />
            <div>
                <input
                    type={"text"}
                    value={props.value || ""}
                    onInput={(e) => {
                        props.onChange?.(e.currentTarget.value);
                    }}
                />
            </div>
        </div>
    );
};

const DropdownField = (props: {
    label: string,
    values: { value: any, label: string }[],
    value?: string,
    onChange?: (value: string) => void
}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', margin: 10 }}>
            <Label text={props.label} />
            <div>
                <select onChange={(e) => props.onChange?.(e.currentTarget.value)}>
                    {
                        props.values.map(x =>
                            <option key={x.value} label={x.label} value={x.value}/>
                        )
                    }
                </select>
            </div>
        </div>
    );
};
const CheckboxField = (props: {
    label: string,
    selected: boolean,
    onChange?: (selected: boolean) => void
}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', margin: 10 }}>
            <div>
                <input
                    type="checkbox"
                    checked={props.selected}
                    onChange={(e) => props.onChange?.(e.target.checked || false)}
                />
                <label>{props.label}</label>
            </div>
        </div>
    );
};
const DateField = (props: {
    label: string,
    value?: string,
    onChange?: (value: string) => void
}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', margin: 10}}>
            <Label text={props.label}/>
            <div>
                <input
                    type={"date"}
                    value={props.value || ""}
                    onInput={(e) => {
                        props.onChange?.(e.currentTarget.value);
                    }}
                />
            </div>
        </div>
    );
};

const findVariables = (dataset?: string) => {
    if (!dataset)
        return [];

    const datasetObj = constants.datasets.find(x => x.value === dataset);
    if (datasetObj)
        return constants.sourceVariables.find(x => datasetObj.label.toLowerCase().startsWith(x.prefix.toLowerCase()))?.variables || [];

    return [];
}


const App = () => {
    const [ dataset, setDataset ] = useState(constants.datasets[0]?.value || undefined);
    const variables = findVariables(dataset);
    const [ variableSet, setVariableSet ] = useState([] as string[]);
    const [ startDate, setStartDate ] = useState("");
    const [ endDate, setEndDate ] = useState("");
    const [ coordinates, setCoordinates ] = useState("");
    const [ areaReducer, setAreaReducer ] = useState(constants.areaReducers?.[0]);

    const [allowRunArt, setAllowRunArt] = useState(false);
    const [runArt, setRunArt] = useState(false);

    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setVariableSet([]);
    }, [dataset]);

    const getData = async () => {
        setRunArt(true);

        setFetching(true);
        if(dataset && variableSet && startDate && endDate && coordinates && areaReducer) {
            const params = {
                dataset,
                variable: variableSet.join(","),
                start_date: startDate,
                end_date: endDate,
                coordinates,
                area_reducer: areaReducer,
                export_format: "json",
            };

            const response = await TimeseriesNativeCoordinates(params);
            const json = await response.json();

            for(const fileData of json) {
                let csvText = "";
                const filename = `${dataset}_${startDate}-${endDate}_${variableSet.join("-")}_${areaReducer}_${fileData?.Metadata?.DRI_OBJECTID}.csv`;
                let headers = [] as string[];
                if(fileData?.Data) {
                    const item = (fileData.Data as any[])[0];
                    headers = Object.keys(item).filter(x => x !== "Date") || [];
                }
                csvText += `Date,${headers.join(",")}\n`;
                for(const line of fileData.Data) {
                    const date = line["Date"];
                    const values = headers.map(x => line[x]);
                    if(values.length === headers.length)
                        csvText += `${date},${values.join(",")}\n`;
                }
                await saveFile(filename, csvText);
            }
        }
        setFetching(false);
    };

    const saveFile = async (filename: string, data: string) => {
        const blob = new Blob([data], {type: "text/plain"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    };

    return (
        <div style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            color: 'white',
            padding: 20,
            overflow: 'auto'
        }}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                    <DropdownField
                        values={constants.datasets.map(x => ({value: x.value, label: x.label}))}
                        value={dataset}
                        label={"Dataset"}
                        onChange={setDataset}
                    />
                    <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', margin: 10}}>
                        <Label text={"Variable"}/>
                        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: "30vh"}}>
                            {
                                variables.map(x =>
                                    <CheckboxField
                                        key={x.value}
                                        selected={variableSet.some(y => y === x.value) || false}
                                        label={x.label}
                                        onChange={(e) => {
                                            const newSet = variableSet.filter(y => y !== x.value);
                                            if (e)
                                                newSet.push(x.value);

                                            setVariableSet(newSet);
                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <DateField label={"StartDate"} value={startDate} onChange={setStartDate}/>
                    <DateField label={"EndDate"} value={endDate} onChange={setEndDate}/>
                    <InputField label={"Coordinates"} value={coordinates} onChange={setCoordinates}/>
                    <DropdownField
                        values={constants.areaReducers.map(x => ({value: x, label: x}))}
                        value={areaReducer}
                        label={"AreaReducer"}
                        onChange={setAreaReducer}
                    />

                    <input
                        style={{marginTop: 20, width: 270}}
                        type={"button"}
                        onClick={getData}
                        value={"Get Data"}
                    />
                    <br/>
                    {
                        fetching &&
                        <span>Fetching Data...</span>
                    }

                    <Art
                        runArt={runArt && allowRunArt}
                        stopRun={() => setRunArt(false)}
                    />
                </div>
                <input
                    type="checkbox"
                    checked={allowRunArt}
                    onClick={(e) => setAllowRunArt(e.currentTarget.checked)}
                />
            </div>
        </div>
    );
}

export default App;
