module.exports = {
    api: {
        url: "https://api.climateengine.org",
        key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczMTM5MTM1MiwianRpIjoiY2M1ZDgzMDItZDc1OC00ZjUxLThjNDgtNGUxOTc1ODg5MTZhIiwibmJmIjoxNzMxMzkxMzUyLCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiM0JadWp0bHB2Z01uMFJlZFhwdEpyUGVGVlczMyIsImV4cCI6MTczNjU3NTM1Miwicm9sZXMiOiJ1c2VyIiwidXNlcl9pZCI6IjNCWnVqdGxwdmdNbjBSZWRYcHRKclBlRlZXMzMifQ.kLrNC6XtqyubyqaYC3d-lNOKUCl-g4C9zicvJ-QyylE"
    },
    datasets: [
        {"label":"CHIRPS - 4.8km - Daily", "value":"CHIRPS_DAILY"},
        {"label":"CHIRPS - 4.8km - Pentad", "value":"CHIRPS_PENTAD"},
        {"label":"CHIRPS Prelim - 4.8km - Pentad", "value":"CHIRPS_PRELIM_PENTAD"},
        {"label":"MERRA2 - 50km - Daily", "value":"MERRA2"},
        {"label":"MERRA2 FWI - 50km - Daily", "value":"MERRA2_FWI"},
        // {"label":"ABoVE BiomeShift - 30m - Yearly", "value":"ABOVE_COVER"},
        // {"label":"ACIS NRCC NN - 5km - Daily", "value":"ACIS_NRCC_NN"},
        // {"label":"ANUSPLIN - 10km - Daily", "value":"ANUSPLIN_DAILY"},
        // {"label":"ANUSPLIN - 10km - Monthly", "value":"ANUSPLIN_MONTHLY"},
        // {"label":"BVHP - 4km - Daily", "value":"BVHP"},
        // {"label":"CAN Drought - 800m - Monthly", "value":"CDOUTLOOK"},
        // {"label":"CEMS FIRE - 800m - Daily", "value":"CEMS"},
        // {"label":"CFS gridMET - 4km - 1to28day", "value":"CFS_GRIDMET"},
        // {"label":"CFS - 19.2km/28.8km - Daily", "value":"CFSV2"},
        // {"label":"CONUS404 - 4km - Daily", "value":"CONUS404"},
        // {"label":"DAYMET - 1km - Daily", "value":"DAYMET"},
        // {"label":"Earthquake - 11km - 2018", "value":"EH"},
        // {"label":"ERA5 - 24km - Daily", "value":"ERA5"},
        // {"label":"ERA5 Land - 11.1km - Daily", "value":"ERA5_LAND_DAILY"},
        // {"label":"ERA5 Land - 11.1km - Monthly", "value":"ERA5_LAND_MONTHLY"},
        // {"label":"ERA5 Ag - 9.6km - Daily", "value":"ERA5_AG"},
        // {"label":"ERA5 HEAT - 27.75km - Daily", "value":"ERA5_HEAT"},
        // {"label":"ESI - 4km - 4week", "value":"ESI_4wk"},
        // {"label":"ESI - 4km - 12week", "value":"ESI_12wk"},
        // {"label":"FLDAS - 9.6km - Monthly", "value":"FLDAS"},
        // {"label":"FRET - 4km - 1to7day", "value":"FRET"},
        // {"label":"GEPS - 55km - 2week", "value":"GEPS_2WK"},
        // {"label":"GEPS - 55km - 4week", "value":"GEPS_4WK"},
        // {"label":"GLDAS - 24km - Daily", "value":"GLDAS"},
        // {"label":"GPM - 11km - Daily", "value":"GPM_DAILY"},
        // {"label":"GridMET - 4km - Daily", "value":"GRIDMET"},
        // {"label":"GridMET Drought - 4km - Pentad", "value":"GRIDMET_DROUGHT"},
        // {"label":"HRDPA - 2.5km - Daily", "value":"HRDPA"},
        // {"label":"HRDPS - 2.5km - Daily", "value":"HRDPS"},
        // {"label":"Landsat 5 TOA - 30m - 16day", "value":"LANDSAT5_TOA"},
        // {"label":"Landsat 7 TOA - 30m - 16day", "value":"LANDSAT7_TOA"},
        // {"label":"Landsat 8 TOA - 30m - 16day", "value":"LANDSAT8_TOA"},
        // {"label":"Landsat 9 TOA - 30m - 16day", "value":"LANDSAT9_TOA"},
        // {"label":"Landsat 5/7/8/9 TOA - 30m", "value":"LANDSAT_TOA"},
        // {"label":"Landsat 5 SR - 30m - 16day", "value":"LANDSAT5_SR"},
        // {"label":"Landsat 7 SR - 30m - 16day", "value":"LANDSAT7_SR"},
        // {"label":"Landsat 8 SR - 30m - 16day", "value":"LANDSAT8_SR"},
        // {"label":"Landsat 9 SR - 30m - 16day", "value":"LANDSAT9_SR"},
        // {"label":"Landsat 5/7/8/9 SR - 30m", "value":"LANDSAT_SR"},
        // {"label":"MODIS ET MOD16A2 - 1km - 8day", "value":"MOD16_TERRANET_ET"},
        // {"label":"MODIS Fire Burned Area - 5oom - Monthly", "value":"MODIS_BURNAREA"},
        // {"label":"MODIS Terra/Aqua - 500m - 16day", "value":"MODIS_16DAY"},
        // {"label":"MODIS Aqua - 250m - 16day", "value":"MODIS_AQUA_16DAY"},
        // {"label":"MODIS Terra - 250m - 16day", "value":"MODIS_TERRA_16DAY"},
        // {"label":"MODIS Aqua - 1km - 8day", "value":"MODIS_AQUA_8DAY"},
        // {"label":"MODIS Terra - 1km - 8day", "value":"MODIS_TERRA_8DAY"},
        // {"label":"MODIS Aqua - 1km - Daily", "value":"MODIS_AQUA_DAILY"},
        // {"label":"MODIS Terra - 1km - Daily", "value":"MODIS_TERRA_DAILY"},
        // {"label":"NADM - 2.5km - Monthly", "value":"NADM"},
        // {"label":"NCEP NCAR - 24km - Daily", "value":"NCEP"},
        // {"label":"NCLIM - 4.6km - Daily", "value":"NCLIMGRID_DAILY"},
        // {"label":"NCLIM - 4.6km - Monthly", "value":"NCLIMGRID_MONTHLY"},
        // {"label":"NLDAS2 - 4.6km - Daily", "value":"NLDAS2_DAILY"},
        // {"label":"NOAA AVHRR - 4km - Daily", "value":"PFV52"},
        // {"label":"PERSIANN CDR - 24km - Daily", "value":"NOAA_CDR_PERSIANN"},
        // {"label":"CPC UPP - 55km - Daily", "value":"NOAA_CPC_DAILY_GLOBAL"},
        // {"label":"CPC UPP - 28km - Daily", "value":"NOAA_CPC_DAILY_CONUS"},
        // {"label":"CPC CMORPH - 25km - Daily", "value":"NOAA_CPC_CMORPH"},
        // {"label":"NOAA OISST - 24km - Daily", "value":"OISST"},
        // {"label":"OpenET - 30m - Monthly", "value":"OPENET_CONUS"},
        // {"label":"MODIS ET PML V2 - 500m - 8day", "value":"PML_ET"},
        // {"label":"PRISM - 4km - Daily", "value":"PRISM"},
        // {"label":"PRISM - 4km - Monthly", "value":"PRISM_MONTHLY"},
        // {"label":"RAP Cover - 30m - Yearly", "value":"RAP_COVER"},
        // {"label":"RAP Production - 30m - Yearly", "value":"RAP_PRODUCTION"},
        // {"label":"RAP Production - 30m - 16day", "value":"RAP_PRODUCTION_16DAY"},
        // {"label":"RAP NDVI - 30m - 16day", "value":"RAP_NDVI"},
        // {"label":"RCMAP - 30m - 16day", "value":"RCMAP"},
        // {"label":"RDPA - 10km - Daily", "value":"RDPA"},
        // {"label":"RDPS - 10km - Daily", "value":"RDPS"},
        // {"label":"RTMA - 2.5km - Daily", "value":"RTMA"},
        // {"label":"Sentinel 2 TOA - 10m - 5day", "value":"SENTINEL2_TOA"},
        // {"label":"Sentinel 2 SR - 10m - 5day", "value":"SENTINEL2_SR"},
        // {"label":"Sentinel 5P - 1km", "value":"SENTINEL5P_ATMOSPHERE"},
        // {"label":"SNODAS - 1km - Daily", "value":"SNODAS"},
        // {"label":"TerraClimate - 4km - Monthly", "value":"TERRACLIMATE"},
        // {"label":"RMM - 28km - Daily", "value":"TMM_DAILY"},
        // {"label":"S Drought - 500m - Monthly", "value":"USDOUTLOOK"},
        // {"label":"SDM - 2.5km - Weekly", "value":"USDM_DROUGHT_MONITOR"},
        // {"label":"SFS TCC - 30m - Yearly", "value":"USFS_TCC"},
        // {"label":"ODIS ET SSEBop - 1km - Dekadel", "value":"USGS_ET_MODIS_DEKADAL"},
        // {"label":"ODIS ET SSEBop - 1km - Monthly", "value":"USGS_ET_MODIS_MONTHLY"},
        // {"label":"ODIS ET SSEBop - 1km - Yearly", "value":"USGS_ET_MODIS_ANNUAL"},
        // {"label":"IIRS ET SSEBop - 1km - Dekadel", "value":"USGS_ET_VIIRS_DEKADAL"},
        // {"label":"IIRS ET SSEBop - 1km - Monthly", "value":"USGS_ET_VIIRS_MONTHLY"},
        // {"label":"IIRS ET SSEBop - 1km - Yearly", "value":"USGS_ET_MODIS_ANNUAL"},
        // {"label":"RC - 30m - 2014", "value":"WRC"},
        // {"label":"egDRI - 1km - Weekly", "value":"VEGDRI"},
        // {"label":"IIRS - 500m - 16day", "value":"VIIRS_16DAY"},
        // {"label":"IIRS - 1km - Daily", "value":"VIIRS_DAILY"}
    ],
    sourceVariables: [ //https://docs.climateengine.org/docs/build/html/variables.html#top
        { prefix: "CHIRPS", variables: [{value: "precipitation", label: "Precipitation"}, {value: "spi", label: "Standard Precipitation Index (SPI)"}] },
        { prefix: "MERRA2 FWI", variables: [{value: "FWI", label: "Fire Weather Index"}] },
        {
            prefix: "MERRA2",
            variables: [
                { label:"Max Temperature (2-m)", value:"T2MMAX" },
                { label:"Min Temperature (2-m)", value:"T2MMIN" },
                { label:"Precipitation", value:"PRECTOTCORR" },
                { label:"ASCE Grass Reference Evapotranspiration (ETo)", value:"ETo_ASCE" },
                { label:"ASCE Alfalfa Reference Evapotranspiration (ETr)", value:"ETr_ASCE" },
                { label:"Potential Water Deficit (PRECTOTCORR - pETo_ASCE)", value:"wb" },
                { label:"Total potential evapotranspiration Hargreaves", value:"peth" },
                { label:"Potential Water Deficit Hargreaves (PRECTOTCORR - peth)", value:"wbh" },
                { label:"Evap. Demand Drought Index (EDDI)", value:"eddi" },
                { label:"Standard Precipitation Index (SPI)", value:"spi" },
                { label:"Standard Prec. Evap. Index (SPEI)", value:"spei" },
                { label:"Evap. Demand Drought Index Hargreaves (EDDI)", value:"eddih" },
                { label:"Standard Prec. Evap. Index Hargreaves (SPEI)", value:"speih" },
            ]
        },
    ],
    areaReducers: ["mean", "median", "min", "max"]
};



