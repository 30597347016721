import constants from "../constants.module";

const api_url = constants.api.url;
const api_key = constants.api.key;
const urlBuilder = (path: string, params: object) => {
    let out = `${api_url}${path.startsWith("/") ? "" : "/"}${path}`;
    const paramKeys = Object.keys(params);
    if(paramKeys.length > 0) {
        out += "?";
        for(const key of paramKeys) {
            // @ts-ignore
            out += `${key}=${params[key]}&`;
        }
    }

    if(out.endsWith("&") || out.endsWith("?"))
        out = out.slice(0, out.length - 1);

    return out;
};

const GET = async (url: string) => {
    return await fetch(url, { headers: new Headers({ 'Authorization': api_key }) });
};


type TimeseriesNativePointsParams = {
    dataset: string,
    variable: string,
    start_date: string,
    end_date: string,
    coordinates: string,
    area_reducer: string,
    buffers?: string
};
export const TimeseriesNativeCoordinates = async (params: TimeseriesNativePointsParams) => {
    const path = "/timeseries/native/coordinates";
    return await GET(urlBuilder(path, params));
};